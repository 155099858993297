import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import Image from "../../components/Image";

const SchoolPage = ({data}) => {
  const {schoolPage: page} = data;
  return (
      <Layout>
        <SEO title={page.metaTags.title} description={page.metaTags.description} />
        
        <h1>{page.title}</h1>
  
        { !!page.image && <Image imgName={page.image} /> }
        
        <div className="page-body" dangerouslySetInnerHTML={{__html: page.body}}/>
      </Layout>
  )
};

SchoolPage.propTypes = {
  data: PropTypes.shape({
    schoolPage: PropTypes.object
  })
};

export default SchoolPage

export const pageQuery = graphql`
    query($id: String!) {
        schoolPage(nid: {eq: $id}) {
            title
            metaTags {
                title
                description
            }
            body
            image
        }
    }
`;
